@font-face {
  font-family: face;
  src: url(./AdBhashitha.ttf);
}

:root {
  --header: 5.05em;
  --reg-font: 1em;
  --h1: 2em;
  --h3: 1.17em;
}
body {
  font-family: face;
}

/* .max_head { */
/* margin: 0.15em auto 0;
  width: fit-content; */
/* margin-top: 10%; */
/* font-size: var(--header);
  font-weight: normal; */
/* } */
h1 {
  font-size: var(--h1);
}
h3 {
  font-size: var(--h3);
}
a,
p,
span,
li {
  font-size: var(--reg-font);
}
li {
  margin: 1em auto;
}
strong {
  font-weight: 999;
}

.content-container {
  min-height: 80vh;
}

.button_hold {
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: space-around;
}

.clapper {
  background-color: #111;
  height: 80px;
  width: 100px;
  position: relative;
}
.clapper > div {
  background: repeating-linear-gradient(
    135deg,
    #111 0%,
    #111 10%,
    #fff 10%,
    #fff 20%
  );
  height: 10px;
  width: 100px;
  position: absolute;
  top: 0;
}
.clapper > div:nth-of-type(1) {
  background: repeating-linear-gradient(
    -135deg,
    #fff 0%,
    #fff 10%,
    #111 10%,
    #111 20%
  );
  top: -10px;
}

.home button {
  background: transparent;
  /* width:33%; */
  padding: 2%;
  /* font-size:2.5em; */
  margin: auto;
  margin-top: 30%;
  display: block;
  border-top-color: rgb(0, 204, 255);
  border-left-color: rgb(0, 204, 255);
  border-right-color: rgb(0, 154, 205);
  border-bottom-color: rgb(0, 154, 205);
  color: rgb(0, 204, 255);
}

.clapper-open {
  animation-name: open;
  transform-origin: left;
  animation-duration: 0.25s;
  animation-timing-function: ease-out;
  animation-direction: forwards;
  animation-fill-mode: forwards;
}

.clapper-close {
  animation-name: close;
  transform-origin: left;
  animation-duration: 0.125s;
  animation-timing-function: ease-out;
  animation-direction: forwards;
  animation-fill-mode: forwards;
}

@keyframes open {
  to {
    transform: rotate(-80deg);
    transform-origin: bottom left;
  }
}

@keyframes close {
  from {
    transform: rotate(-80deg);
  }
  to {
    transform: rotate(0deg);
  }
}

#navbar {
  text-align: center;
  margin-top: -1%;
}

.max_nav_bar {
  margin-bottom: -2%;
}

.subhead {
  margin-top: -2%;
}

/* Add a black background color to the top navigation */
.topnav {
  display: flex;
  justify-content: space-evenly;
  margin-top: 0;
  align-items: center;
}

a {
  color: black;
  cursor: pointer;
  text-decoration: none;
}

a:hover {
  color: grey;
  text-decoration: underline;
}
.home {
  min-height: 90vh;
  display: flex;
  align-items: center;
}
.item > p {
  margin-top: 0;
}
.poster {
  width: 75%;
}

.caption {
  font-size: 1.5em;
}

.aboutpage {
  width: 90%;
  margin: auto;
  text-align: center;
}
.biopic {
  width: 75%;
}
.max_text {
  width: 75%;
  margin: auto;
}

.max_text > p {
  font-size: 0.75em;
  line-height: 1.3;
}
.icon-container {
  width: 3%;
  margin: 1em;
}
.icon {
  width: 100%;
}
.about-icon-container {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
}
.about-icon {
  width: 5%;
  margin: 1em;
  cursor: pointer;
}
.about-icon-container > span {
  margin: 0.5em;
}

@media all and (min-width: 620px) {
  .poster_holder {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .item {
    vertical-align: top;
    display: inline-block;
    text-align: center;
    width: 40%;
  }
  .icon-container {
    width: 2%;
  }
}

@media screen and (min-width: 900px) {
  .about-container {
    display: flex;
    flex-direction: row;
  }
  .aboutpage > h1:first-of-type {
    text-align: center;
    margin-left: 35%;
    margin-top: 0;
  }
  .biopic {
    width: 35%;
    height: 100%;
    margin: 0 1em;
  }
  .max_text {
    width: 50%;
    margin: 0 auto;
  }
  .max_text > p {
    font-size: 1.2em;
    margin: auto;
  }
  .max_text > h1:first-of-type {
    margin-top: 0;
  }
}

.redhook {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  align-items: center;
}
.redhook_title_img {
  width: 40%;
  margin-top: 1em;
}
.redhook > h2 {
  position: absolute;
  text-align: center;
  top: 0;
  width: 100%;
  color: rgb(197, 35, 6);
  font-weight: 1000px;
  font-size: 2em;
}
.redhook > iframe {
  width: 90%;
  height: 30em;
}
.redhook > h3:first-of-type {
  margin: 1em auto auto;
}
.redhook > h3,
.redhook > p,
.redhook > h1 {
  text-align: center;
}
.redhook > h3 {
  font-style: italic;
}
.redhook > p,
.redhook > h3 {
  width: 80%;
  margin: auto;
}
.smoking_ss {
  width: 100%;
  height: 50vh;
}

.stills-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.stills-container > img {
  width: 45%;
  margin: 2em auto;
}

.glacial {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 80vh;
}

.glac_text {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* height: 80vh; */
  line-height: 1%;
  margin-bottom: 2%;
}
.footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.movie_poster {
  width: 50%;
  margin: 2em auto;
}

@media screen and (max-width: 660px) {
  :root {
    --reg-font: 0.4em;
    --h1: 1em;
    --h3: 0.7em;
  }
  .item {
    text-align: center;
    margin-bottom: 2em;
  }
  .work_together {
    background-color: black;
    color: white;
    width: 125%;
    border-radius: 20px;
    font-size: 0.75em;
    margin-bottom: 2%;
  }

  .redhook > iframe {
    width: 90%;
    height: 10em;
    margin: 1em;
  }

  .stills-container > img {
    width: 90%;
  }
}

.bold_film {
  font-weight: bold;
}
